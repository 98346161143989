import eventsSlider from "./lib/eventsSlider";

const frontpage = () => {
  eventsSlider();

  // Change bg venue background
  var venueBtns = document.querySelectorAll(".veanu-bg-trigger");
  var venueImage = document.querySelectorAll(".frontpage--event-bg");

  venueBtns[0].classList.add("veanu-bg-trigger-active");
  venueImage[0].classList.add("frontpage--event-bg-active");

  venueBtns.forEach(function (venueBtn) {
    venueBtn.addEventListener("mouseover", function (text) {
      const selectedElement = text.target.getAttribute("event-bg");
      console.log(selectedElement);

      venueBtns.forEach(function (trigger) {
        trigger.classList.remove("veanu-bg-trigger-active");
      });

      venueBtn.classList.add("veanu-bg-trigger-active");

      venueImage.forEach((image) => {
        const imageVenue = image.getAttribute("image");
        if (image.id === `event-bg--${selectedElement}`) {
          image.classList.add("frontpage--event-bg-active");
        } else {
          image.classList.remove("frontpage--event-bg-active");
        }
      });
    });
  });

  // Reveal Hero Mailing list form
  const SubscribeBtnElement = document.getElementById(
    "frontpage--reveal-form-mailing-list--hero",
  );
  const SubscribeFormWrapElement = document.getElementById(
    "frontpage--form-mailing-list--hero",
  );
  const SubscribeFormElement = document.querySelector(
    "#frontpage--form-mailing-list--hero .wpcf7",
  );
  const SubscribeThanksElement = document.getElementById(
    "frontpage--form-mailing-list--thanks",
  );
  SubscribeBtnElement.addEventListener("click", () => {
    SubscribeBtnElement.style.opacity = "0";
    SubscribeBtnElement.style.pointerEvents = "none";
    SubscribeFormWrapElement.style.opacity = "1";
    SubscribeFormWrapElement.style.pointerEvents = "all";
  });
  SubscribeFormElement.addEventListener(
    "wpcf7mailsent",
    () => {
      SubscribeFormWrapElement.style.opacity = "0";
      SubscribeFormWrapElement.style.pointerEvents = "none";
      SubscribeThanksElement.style.opacity = "1";
      SubscribeThanksElement.style.pointerEvents = "all";
    },
    false,
  );
};

frontpage();
export default frontpage;
